<template>
  <section>
    <h2 class="page-title-bar">
      <i class="ico ico-multi"></i>{{ $t('Label OTA') }}
    </h2>
    <div class="d-flex mt-8">
      <div class="form-group" style="width: 190px">
        <span class="label-txt">{{ $t('OTA On/Off') }}</span>
        <v-select
          v-model="otaScheduleEnabled"
          @input="handleOtaScheduleEnabledInput"
          :disabled="otaSettingEditDisabled"
          :items="otaScheduleEnabledItems"
          item-text="txt"
          item-value="val"
          :placeholder="$t('On')"
          class="form-select"
          outlined
          dense
          hide-details
        >
        <template v-slot:item="{item }">
         <span style="font-size: 0.8125rem;">{{ $t(item.txt) }}</span>
        </template>
        <template v-slot:selection="{item }">
         <span class="LedPatternClass" style="display: contents !important;">{{ $t(item.txt) }}</span>
        </template>
      </v-select>
      </div>
      <ota-setting
        :key="selectedStore.code"
        :otaSettingEditDisabled="otaSettingEditDisabled || !otaScheduleEnabled"
        :otaSettingPopupDisabled="otaSettingPopupDisabled"
      />
    </div>

    <div
      :class="!otaScheduleEnabled ? 'is-off' : null"
      class="updateStatus mt-13"
    >
      <div class="total_update">
        <div>
          <p class="tit">
            <strong>{{ $t('TOTAL LABEL') }}</strong
            >{{ $t('Update Status') }}
          </p>
          <div class="graphBox">
            <canvas id="total" width="220" height="220"></canvas>
            <div class="score">
              <p class="percent">
                <strong>{{ totalTagStatusPercentage }}</strong
                >%
              </p>
              <p class="count">{{ totalTagStatusFraction }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!otaScheduleEnabled" class="list_update custom-scrollbar">
        <ul>
          <li
            v-for="obj of [
              { id: 0 },
              { id: 1 },
              { id: 2 },
              { id: 3 },
              { id: 4 },
              { id: 5 },
              { id: 6 }
            ]"
            :key="obj.id"
          >
            <p class="nodata">{{ $t('No data available') }}</p>
          </li>
        </ul>
      </div>
      <div v-else-if="tagStatusListExist" class="list_update custom-scrollbar">
        <ul>
          <li
            v-for="(tagStatus,index) of tagStatusList"
            :key="tagStatus.typeCode"
            class="tagStatusList"
            @click.stop='getOtaStatus(tagStatus,index)'
            v-labelOtaEnabled="tagStatus.otaMode"
            style="justify-content: unset;align-items: stretch;flex-direction: column;">
          <div style="display: flex;align-items: center;justify-content: space-between;cursor: pointer;">
            <p class="name">
              {{ tagStatus.system }}
              <strong>{{ getTagTypeInfo(tagStatus) }} </strong>
            </p>
            <div class="info_graph">
              <div class="graphBox">
                <div class="score">
                  <p class="percent">
                    <strong>{{ getTagUpdatedPercentage(tagStatus) }}</strong
                    >%
                  </p>
                </div>
              </div>
              <div class="data">
                <div>
                  <p>
                    Quantity :
                    <span>{{ getTagUpdatedFraction(tagStatus) }}</span>
                  </p>
                  <!-- <p>
                    Firmware Version :
                    <span>{{
                      tagStatus.fwVersion ? `V${tagStatus.fwVersion}` : ''
                    }}</span>
                  </p> -->
                </div>
              </div>
            </div>
          </div>

          <!-- //Details -->
          <div v-if="toggleclass">
            <ul v-if="tagClassStatusList.length !==0 && OtaStatusIndex===index">
          <li
            v-for="tagStatusDetail of tagClassStatusList"
            :key="tagStatusDetail.typeCode"
            class="tagStatusListDetail"
            v-bind:class = "(tagStatusDetail.firmwareVersion===0)?'detailClassFWtrue':'detailClassFWfalse'"
            v-labelOtaEnabled="toggleclass"
          >
          <p class="name" style="font-size:14px;">
              {{ tagStatusDetail.tagClass }}
            </p>
            <div class="info_graph">
              <div class="graphBox">
                <div class="score">
                  <p class="percent" v-bind:class = "(tagStatusDetail.firmwareVersion===0)?'detailpercentClassFWtrue':''">
                    <strong>{{ getTagUpdatedPercentageStatus(tagStatusDetail) }}</strong
                    >%
                  </p>
                </div>
              </div>
              <div class="data">
                <div>
                  <p>
                    Quantity :
                    <span>{{ getTagUpdatedFractionStatus(tagStatusDetail) }}</span>
                  </p>
                  <p>
                    Firmware Version :
                    <span>{{
                      tagStatusDetail.firmwareVersion ? `V${tagStatusDetail.firmwareVersion}` : 'V0'
                    }}</span>
                  </p>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <!-- End -->
          </li>
        </ul>

      </div>
      <div v-else class="txt-no-data">
        <p>{{ $t('No data available') }}</p>
      </div>
    </div>
    <msg-box :msgBox="msgBox" @closeMsgBox="closeMsgBox" />

    <br>
    <div style="position:relative;margin-right: 50px;">
    <div class="pageInfo"> {{ pageInfoText }} </div>
      <div>
        <v-pagination
          v-model="page"
          @input="paging"
          :length="totalPages"
          :total-visible="7"
           class="pageAlign"
        ></v-pagination>
      </div>
    </div>

 <!-- Confirm Box -->
 <v-dialog v-model="confirmBoxShow" persistent width="500">
      <v-card class="popup add_store_popup" align="center" justify="center">
        <v-row>
          <v-col>
            <span>{{ $t('Are you sure you want to turn the OTA off?')}}</span>
          </v-col>
        </v-row>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="afterConfirm" text icon class="btn">
            {{ $t('Yes') }}
          </v-btn>
          <v-btn @click="closeConfirm" text icon class="btn">
            {{ $t('No') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- confirm box close -->

  </section>
</template>

<script>
// Components
import OtaSetting from '@/views/Firmware/modal/OtaSetting'
// Mixins
import MsgBoxParent from '@/mixins/MsgBoxParent'
import SelectedStoreWatcher from '@/mixins/SelectedStoreWatcher'
// Utils
import codes from '@/plugins/codes'
import commons from '@/plugins/commons'
import EventBus from '@/plugins/eventBus'
// 3rd party libraries
import Chart from 'chart.js'

const requests = {
  otaStatus: {
    method: 'get',
    url: '/api/common/otamanager/api/status/tagList/detail'
  }
}
export default {
  mixins: [MsgBoxParent, SelectedStoreWatcher],
  directives: {
    labelOtaEnabled: {
      inserted: function (el, binding) {
        if (commons.isNull(binding.value) || binding.value === 0) {
          // firmware version is empty, and OTA is disabled
          el.classList.add('disabled')
        }
      }
    }
  },
  name: 'LabelOTA',
  components: {
    OtaSetting
  },
  data () {
    return {
      // Button permissions
      otaSettingEditDisabled: false,
      otaSettingPopupDisabled: false,
      // Tag Status
      otaScheduleEnabledItems: [
        { txt: codes.toggle.ON, val: true },
        { txt: codes.toggle.OFF, val: false }
      ],
      otaScheduleEnabled: true,
      totalTagStatus: {},
      tagStatusList: [],
      // Charts
      totalLabelStatusChart: null,
      labelStatusListChart: null,
      pageInfoText: '',
      page: 1,
      totalPages: 0,
      tagClassStatusList: [],
      OtaStatusIndex: '',
      toggleclass: false,
      count: 1,
      confirmBoxShow: false
    }
  },
  computed: {
    totalTagStatusPercentage () {
      const { updatedLabel, totalLabel } = this.totalTagStatus
      if (!this.otaScheduleEnabled) return 0
      if (!commons.isAllValid([updatedLabel, totalLabel]) || totalLabel === 0) {
        return 0
      }
      const totalTagStatusInPercent = (
        (updatedLabel / totalLabel) *
        100
      ).toFixed(1)
      return `${totalTagStatusInPercent}`
    },
    totalTagStatusFraction () {
      const { totalLabel, updatedLabel } = this.totalTagStatus
      if (!this.otaScheduleEnabled) {
        return this.$t('(No data available / No data available)')
      }
      if (!commons.isAllValid([updatedLabel, totalLabel]) || totalLabel === 0) {
        return '0/0'
      }
      return `${updatedLabel}/${totalLabel}`
    },
    tagStatusListExist () {
      return this.tagStatusList?.length > 0
    }
  },
  mounted () {
    // Select the company or load data.
    EventBus.$emit('enableSelectedStores', true)
    if (!this.selectedStore?.code) {
      EventBus.$emit('openSelectedStores')
      this.drawCharts()
    } else {
      this.initiateLabelOta()
    }
    // Check what buttons are inaccessable
    this.$store.dispatch('auth/getDisabledBtn', '10200').then(disabled => {
      this.otaSettingEditDisabled = disabled
    })
    this.$store.dispatch('auth/getDisabledBtn', '10200').then(disabled => {
      this.otaSettingPopupDisabled = disabled
    })
  },
  methods: {
    afterConfirm () {
      // When user confirms OTA Off
      this.disableOtaSchedule(this.selectedStore?.code)
      this.otaScheduleEnabled = false
      this.drawCharts()
      this.confirmBoxShow = false
    },
    // Event handlers
    async handleOtaScheduleEnabledInput (otaScheduleEnabled) {
      if (otaScheduleEnabled) {
        await this.$nextTick()
        this.drawCharts()
      } else if (otaScheduleEnabled === false) {
        this.confirmBoxShow = true
      }
    },
    closeConfirm () {
      this.otaScheduleEnabled = true
      this.confirmBoxShow = false
    },
    // Chart.js
    drawCharts () {
      const totalLabelStatusChart = new Chart('total', {
        type: 'doughnut',
        data: {
          datasets: [
            {
              label: 'total',
              backgroundColor: ['#fff', '#3d5468'],
              borderWidth: 0
            }
          ]
        },
        plugins: [
          {
            beforeInit: chart => {
              const percent = Number(
                document.querySelector('.total_update .percent strong')
                  .textContent
              )
              const dataset = chart.data.datasets[0] // chart: chart instance, myChartCircle
              chart.data.labels = [dataset.label]
              dataset.data = [percent, 100 - percent] // doughnut 차트이므로 2개의 data로 진행률을 표현.
              if (percent === 0 || !this.otaScheduleEnabled) {
                dataset.data = [100, 0]
              }
            }
          }
        ],
        options: {
          animation: false,
          maintainAspectRatio: false,
          cutoutPercentage: 85,
          rotation: Math.PI * -0.5,
          legend: {
            display: false
          },
          tooltips: {
            enabled: false
          }
        }
      })
      this.totalLabelStatusChart = totalLabelStatusChart

      const labelStatusListChart = document.querySelectorAll('.tagStatusList')
      labelStatusListChart.forEach(function (el) {
        const graphBox = el.querySelector('.graphBox')
        const percent = Number(
          graphBox.querySelector('.percent strong').textContent
        )
        const colors = el.classList.contains('disabled')
          ? ['#CBCBCB', '#ffffff00']
          : ['#81A2BE', '#ffffff00']
        const canvas = document.createElement('canvas')
        if (graphBox.querySelector('canvas')) {
          return
        } else {
          graphBox.appendChild(canvas)
        }
        const ctx = canvas.getContext('2d')
        const childChart = new Chart(ctx, {
          type: 'doughnut',
          data: {
            datasets: [
              {
                label: 'total',
                backgroundColor: colors,
                borderWidth: 0
              }
            ]
          },
          plugins: [
            {
              beforeInit: chart => {
                const dataset = chart.data.datasets[0]
                chart.data.labels = [dataset.label]
                dataset.data = [percent, 100 - percent]
              }
            }
          ],
          options: {
            animation: false,
            maintainAspectRatio: false,
            cutoutPercentage: 80,
            circumference: Math.PI,
            rotation: Math.PI,
            legend: { display: false },
            tooltips: { enabled: false }
          }
        })
        childChart.toString()
      })
      this.labelStatusListChart = labelStatusListChart
    },
    // etc
    afterSelectedStoreUpdate () {
      this.initiateLabelOta()
    },
    async initiateLabelOta () {
      this.tagClassStatusList = []
      this.totalTagStatus = await this.getTotalTagStatus(
        this.selectedStore?.code
      )
      this.tagStatusList = await this.getTagStatusList(this.selectedStore?.code, this.page)
      this.otaScheduleEnabled = await this.getOtaEnable(
        this.selectedStore?.code
      )
      this.$nextTick(() => {
        this.drawCharts()
      })
    },
    getTagTypeInfo (tagStatus) {
      if (!commons.isValidData(tagStatus).result) return ''
      let { type, inch, typeCode } = tagStatus
      inch = `${inch.replace(/p|P/g, '.')}"`
      typeCode = `(${typeCode})`
      return `${type} ${inch} ${typeCode}`
    },
    getTagUpdatedPercentage (tagStatus) {
      const { updatedlabel, totallabel } = tagStatus
      if (!commons.isAllValid([updatedlabel, totallabel]) || totallabel === 0) {
        return 0
      }
      return Math.floor((updatedlabel / totallabel) * 100)
    },
    getTagUpdatedPercentageStatus (tagStatus) {
      const { updateCount, totalCount } = tagStatus
      console.log(tagStatus)
      if (tagStatus.firmwareVersion === 0) {
        return 100
      } else {
        if (!commons.isAllValid([updateCount, totalCount]) || totalCount === 0) {
          return 0
        }
        return Math.floor((updateCount / totalCount) * 100)
      }
    },
    getTagUpdatedFractionStatus (tagStatus) {
      const { updateCount, totalCount } = tagStatus
      if (!commons.isAllValid([updateCount, totalCount])) return '0ea / 0ea'
      return `${updateCount}ea / ${totalCount}ea`
    },
    getTagUpdatedFraction (tagStatus) {
      const { updatedlabel, totallabel } = tagStatus
      if (!commons.isAllValid([updatedlabel, totallabel])) return '0ea / 0ea'
      return `${updatedlabel}ea / ${totallabel}ea`
    },

    // Added New
    handleGetTotalTagResponse (res) {
      console.log(res)
      if (res.status === 204) {
        const headers = {}
        headers['x-totalelements'] = 0
        this.pageInfoText = commons.getPageInfoText(headers)
        this.totalPages = 0
      } else {
        this.pageInfoText = commons.getPageInfoText(res.headers)
        console.log(this.pageInfoText)
        if (commons.isValidStr(res.headers['x-totalpages'])) {
          this.totalPages = res.headers['x-totalpages'] * 1
        }
      }
    },
    handleGetTotalTagError (error) {
      this.labelFirmwareList = []
      const headers = {}
      headers['x-totalelements'] = 0
      this.pageInfoText = commons.getPageInfoText(headers)
      this.totalPages = 0
      console.debug(`Could not find label firmware. error: ${error}`)
    },

    paging () { this.initiateLabelOta() },

    // API
    getTotalTagStatus (stationCode) {
      const config = { params: {} }
      return this.$utils
        .callAxios(
          codes.requests.getTotalTagStatus.method,
          `${codes.requests.getTotalTagStatus.url}/${stationCode}`,
          config
        )
        .then(res => {
          return res.data
        })
        .catch(error => {
          console.debug(
            `Could not find label status(label OTA information). error: ${error}`
          )
        })
    },
    getTagStatusList (stationCode, page) {
      if (!commons.isValidStr(stationCode)) return
      const config = { params: {} }
      config.params.page = page - 1
      return this.$utils
        .callAxios(
          codes.requests.getTagStatusList.method,
          `${codes.requests.getTagStatusList.url}/${stationCode}`,
          config
        )
        .then(res => {
          this.handleGetTotalTagResponse(res)
          if (res.data.responseCode === 204) return []
          return res.data.tagStatus
        })
        .catch(error => {
          this.handleGetTotalTagError(error)
          console.debug(
            `Could not find label status(label OTA information). error: ${error}`
          )
        })
    },
    getOtaEnable (stationCode) {
      if (!commons.isValidStr(stationCode)) return
      const config = { params: {} }
      return this.$utils
        .callAxios(
          codes.requests.getOtaEnable.method,
          `${codes.requests.getOtaEnable.url}/${stationCode}`,
          config
        )
        .then(({ data }) => {
          return data?.enable
        })
        .catch(error => {
          console.debug(`Could not find OTA setting. error: ${error}`)
        })
    },
    disableOtaSchedule (storeCode) {
      if (!commons.isValidStr(storeCode)) return
      const data = {}
      const params = {}
      const config = { data, params }
      return this.$utils
        .callAxios(
          codes.requests.disableOtaSchedule.method,
          `${codes.requests.disableOtaSchedule.url}/${storeCode}`,
          config
        )
        .then(res => {
          this.openMsgBox(this.$t(res.data.responseMessage))
        })
        .catch(error => {
          console.debug(`Failed to off the OTA schedule. error: ${error}`)
        })
    },

    getOtaStatus (data, index) {
      this.tagClassStatusList = []
      this.count++
      if (this.OtaStatusIndex !== index) {
        this.count = 1
      }
      console.log(this.count)
      if (this.count % 2 === 0) {
        this.toggleclass = false
      } else {
        this.toggleclass = true
      }
      this.OtaStatusIndex = index
      var config
      config = { params: { } }
      var stationCode = this.$store.state.dataStore.selectedStore.code
      var firmwareId = data.id
      this.$utils
        .callAxios(
          requests.otaStatus.method,
          `${requests.otaStatus.url}/${stationCode}/${firmwareId}`,
          config
        )
        .then(res => {
          console.log(res)
          this.tagClassStatusList = res.data.tagClassStatusList
          setTimeout(() => {
            this.drawChartsforStatus()
          }, 80)
        })
        .catch(error => {
          console.debug(`Failed to load tagClassStatusList. error: ${error}`)
          this.tagClassStatusList = []
        })
    },
    drawChartsforStatus () {
      const labelStatusListChart = document.querySelectorAll('.tagStatusListDetail')
      labelStatusListChart.forEach(function (el) {
        const graphBox = el.querySelector('.graphBox')
        const percent = Number(
          graphBox.querySelector('.percent strong').textContent
        )
        const colors = el.classList.contains('detailClassFWtrue')
          ? ['#CBCBCB', '#ffffff00']
          : ['#81A2BE', '#ffffff00']
        const canvas = document.createElement('canvas')
        if (graphBox.querySelector('canvas')) {
          return
        } else {
          graphBox.appendChild(canvas)
        }
        const ctx = canvas.getContext('2d')
        const childChart = new Chart(ctx, {
          type: 'doughnut',
          data: {
            datasets: [
              {
                label: 'total',
                backgroundColor: colors,
                borderWidth: 0
              }
            ]
          },
          plugins: [
            {
              beforeInit: chart => {
                const dataset = chart.data.datasets[0]
                chart.data.labels = [dataset.label]
                dataset.data = [percent, 100 - percent]
              }
            }
          ],
          options: {
            animation: false,
            maintainAspectRatio: false,
            cutoutPercentage: 80,
            circumference: Math.PI,
            rotation: Math.PI,
            legend: { display: false },
            tooltips: { enabled: false }
          }
        })
        childChart.toString()
      })
      this.labelStatusListChart = labelStatusListChart
    }
  }
}
</script>

<style scoped>
/* store - status - 그래프 */
::v-deep .updateStatus {
  display: flex;
  justify-content: space-between;
  max-width: 1100px;
  height: 576px;
}
::v-deep .updateStatus .total_update {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32%;
  min-width: 280px;
  color: #fff;
  background: #001E38;
}
::v-deep .updateStatus .total_update .tit {
  margin-bottom: 50px;
  font-size: 21px;
  text-align: center;
}
::v-deep .updateStatus .total_update .tit strong {
  display: block;
  font-size: 29px;
}
::v-deep .updateStatus .total_update .graphBox {
  position: relative;
  width: 220px;
  height: 220px;
}
::v-deep .updateStatus .total_update .graphBox .score {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 20px;
  width: 180px;
  height: 180px;
  border: 1px solid #3D5468;
  border-radius: 50%;
}
::v-deep .updateStatus .total_update .graphBox .percent {
  font-size: var(--font40);
  font-weight: 700;
  line-height: 1;
}
::v-deep .updateStatus .total_update .graphBox .count {
  margin-top: 5px;
  font-size: var(--font15);
}
::v-deep .updateStatus .list_update {
  width: calc(100% - 32% - 40px);
  min-width: 600px;
  padding: 0 10px 5px;
  overflow-y: auto;
}
::v-deep .updateStatus .list_update ul {
  padding-left: 0 !important;
}
::v-deep .updateStatus .list_update li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029;
  background: #FAFAFA;
  color: #001E38;
}
::v-deep .updateStatus .list_update li+li {
  margin-top: 10px;
}
::v-deep .updateStatus .list_update li .info_graph {
  display: flex;
  align-items: center;
  width: 55%;
}
::v-deep .updateStatus .list_update li .name {
  padding: 20px 0 15px 8%;
  font-size: 12px;
  font-weight: 700;
}
::v-deep .updateStatus .list_update li .name strong {
  display: block;
  font-size: 17px;
  font-weight: 400;
  line-height: 1;
}
::v-deep .updateStatus .list_update li .graphBox {
  margin-right: 10%;
}
::v-deep .updateStatus .list_update li .data {
  display: flex;
  align-items: center;
  position: relative;
  min-width: 200px;
  height: 75px;
  padding: 0 42px;
  border-left: 1px solid #E5E5E5;
  font-size: 12px;
}
::v-deep .updateStatus .list_update li .graphBox {
  position: relative;
  width: 100px;
  height: 50px;
}
::v-deep .updateStatus .list_update li .graphBox .score {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  top: 4px;
  left: 4px;
  width: 92px;
  height: 46px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  border: 2px solid #EFEFEF;
  border-bottom: 0;
  box-sizing: border-box;
}
::v-deep .updateStatus .list_update li .graphBox .percent {
  font-size: var(--font25);
  font-weight: 700;
  line-height: 1;
  color: #81A2BE;
  letter-spacing: -0.05em;
}
::v-deep .updateStatus .list_update li .graphBox canvas {
  position: relative;
}
::v-deep .updateStatus .list_update .disabled {
  background: #EFEFEF !important;
  color: #D4D4D4;
}
::v-deep .updateStatus .list_update .disabled .graphBox .percent {
  color: #CDCDCD;
}
::v-deep .txt-no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 32% - 40px);
  height: 576px;
  border: 1px solid #EFEFEF;
}
::v-deep .txt-no-data p {
  font-size: var(--font18);
  color: #031E37;
}
/* width */
::v-deep .custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::v-deep .custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
::v-deep .custom-scrollbar::-webkit-scrollbar-thumb {
  background: #818181;
  border-radius: 5px;
}
/* Handle on hover */
::v-deep .custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
::v-deep .label-txt {
  display: inline-block;
  margin-right: 25px;
  font-size: var(--font15);
  color: #808495;
  white-space: nowrap;
}
::v-deep .form-group {
  display: flex;
  align-items: center;
}
::v-deep .form-group .v-btn.btn {
  width: 100px;
}
::v-deep .form-group .v-btn.btn .v-btn__content {
  line-height: 40px;
}
::v-deep .form-group .form-select.v-text-field--outlined {
  padding-top: 0;
}
::v-deep .btn-red {
  width: 190px;
  height: 40px !important;
  color: #fff !important;
  background: #E27070;
  font-size: var(--font16) !important;
}
::v-deep .updateStatus.is-off .total_update {
  color: #CDCDCD;
  background: #EFEFEF;
  box-shadow: 0px 3px 6px #00000029;
}
::v-deep .updateStatus.is-off .count {
  width: 105px;
}
::v-deep .updateStatus.is-off .total_update .graphBox .score {
  border: 1px solid #CDCDCD;
}
::v-deep .updateStatus.is-off .list_update .nodata {
  width: 100%;
  font-size: var(--font14);
  line-height: 73px;
  text-align: center;
  background: #EFEFEF;
  color: #CDCDCD;
}
::v-deep .pageAlign {
  float: right;
  margin-top: 20px;
}
::v-deep .otaModeTrue {
  background-color: red;
}
::v-deep .otaModeFalse {
  background-color: blue;
}
::v-deep .detailClassFWtrue {
  background: #EFEFEF !important;
  color: #D4D4D4 !important;
}
::v-deep .detailClassFWfalse {
  background: #444F63 !important;
  color: white !important;
}
::v-deep .detailpercentClassFWtrue {
  color: #CDCDCD !important;
}
::v-deep .LedPatternClass+input {
  display: contents !important;
}
</style>
<style>
.flex-layout {
  display: flex;
  justify-content: space-between;
}
.flex-layout .section-title {
  font-size: var(--font-18);
}
.flex-layout .centerBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
  background: #E27070;
  border-radius: 2px;
  box-shadow: 0px 3px 6px #00000029;
}
.flex-layout .centerBox .v-btn {
  margin: 20px 0;
  background: #E27070;
  color: #fff;
  font-size: var(--font15);
  font-weight: 700;
}
.flex-layout .centerBox .v-btn__content {
  flex-direction: column;
}
.flex-layout .centerBox .v-btn__content strong {
  display: block;
  margin-top: 10px;
}
.flex-layout .leftBox {
  width: calc((100% - 110px) / 2);
}
.flex-layout .leftBox .tbl-type01 thead th {
  height: 58px !important;
  padding: 16px 5px;
}
.flex-layout .leftBox .tbl-type01 thead th:first-child {
  padding: 16px;
}
.flex-layout .leftBox .tbl-type01.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  padding: 0 5px !important;
}
.flex-layout .leftBox .tbl-type01.v-data-table>.v-data-table__wrapper>table>tbody>tr>td:first-child {
  padding: 0 16px !important;
}
.flex-layout .leftBox .tbl-type01.theme--light.v-data-table.v-data-table--fixed-header thead th {
  color: #001E38 !important;
  background: #EFEFEF;
}
.flex-layout .leftBox .tbl-type01.theme--light.v-data-table th .theme--light.v-icon.mdi-checkbox-marked {
  color: #001E38 !important;
}
.flex-layout .leftBox .tbl-type01.theme--light.v-data-table tbody tr.v-data-table__selected td {
  border-bottom: 1px solid #fff !important;
  color: #fff !important;
  font-weight: 400;
  background: #001E38;
}
.flex-layout .leftBox .tbl-type01.theme--light.v-data-table tbody tr.v-data-table__selected .theme--light.v-icon:before {
  color: #fff !important;
}
.flex-layout .rightBox {
  width: calc((100% - 110px) / 2);
}
.flex-layout .rightBox .tbl-type01 {
  border: 1px solid #EFEFEF;
  background: #EFEFEF;
}
.flex-layout .rightBox .tbl-type01 thead th {
  height: 58px !important;
  background: #fff;
  padding: 16px 5px;
}
.flex-layout .rightBox .tbl-type01 thead th:first-child {
  padding: 16px;
}
.flex-layout .rightBox .tbl-type01.theme--light.v-data-table.v-data-table--fixed-header thead th {
  background: #fff;
  color: #001E38 !important;
}
.flex-layout .rightBox .tbl-type01.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  padding: 0 5px !important;
}
.flex-layout .rightBox .tbl-type01.v-data-table>.v-data-table__wrapper>table>tbody>tr>td:first-child {
  padding: 0 16px !important;
}
.flex-layout .rightBox .tbl-type01.theme--light.v-data-table th .theme--light.v-icon.mdi-checkbox-marked {
  color: #001E38 !important;
}
.flex-layout .rightBox .tbl-type01 tbody td {
  color: #5A5A5A !important;
  border-bottom: none !important;
  background: transparent;
}
</style>
