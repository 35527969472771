<template>
  <v-dialog
    v-model="otaSettingVisible"
    scrollable
    persistent
    width="auto "
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        @click="initiateStoreSetting"
        :disabled="otaSettingPopupDisabled"
        :class="otaSettingEditDisabled ? 'disabled' : null"
        text
        class="btn-red ml-8"
      >
        {{ $t('OTA Setting') }}
      </v-btn>
    </template>

    <v-card class="popup add_store_popup">
      <v-card-title>
        <h3 class="page-title-bar">
        <i class="ico ico-multi"></i>{{ $t('OTA Setting') }}
      </h3>
      </v-card-title>
      <v-card-text >
      <div class="d-flex mt-5">
        <div class="form-group" >
          <!-- <span class="label-txt">{{ $t('Schedule Setting') }}</span> -->
          <span class="label-txt">{{ $t('Update Start') }}</span>

          <v-menu
            v-model="calendarVisible"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="otaScheduleDate"
                v-bind="attrs"
                v-on="on"
                class="form-input"
                placeholder="Input the date"
                outlined
                dense
                hide-details
                solo
                readonly
              >
                <template v-slot:append>
                  <img
                    width="16"
                    height="16"
                    src="@/assets/img/ico-calendar.png"
                  />
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="otaScheduleDate"
              @input="calendarVisible = false"
              :locale="languageCodes[$store.getters['dataStore/GET_CURRENT_LANGUAGE']]"
              no-title
              scrollable
            >
            </v-date-picker>
          </v-menu>
          <v-select
            v-model="otaScheduleTimeHour"
            :items="otaScheduleTimeHourOptions"
            placeholder="--"
            class="form-select ml-3"
            style="width: 90px; padding-top: 0px"
            outlined
            dense
            hide-details
          ></v-select>
          <v-select
            v-model="otaScheduleTimeMinute"
            :items="otaScheduleTimeMinuteOptions"
            placeholder="--"
            class="form-select ml-2"
            style="width: 90px; padding-top: 0px"
            outlined
            dense
            hide-details
          ></v-select>
          <br>

        </div>

      </div>
      <div class="d-flex mt-5">
          <span class="label-txt"> * {{ $t('The Update process runs regularly for up to 5 hours a day until it is completed.') }}</span>
        </div>

      <div class="flex-layout"  style="padding-top:20px">
        <div class="leftBox" style="width:350px">
          <h3 class="section-title">{{ $t('STORE LABEL LIST') }}</h3>
          <v-data-table
            v-model="selectedStoreLabels"
            :headers="storeLabelHeaders"
            :items="storeLabels"
            item-key="typeCode"
            :hide-default-footer="true"
            show-select
            fixed-header
            :items-per-page="-1"
            height="300"
            class="tbl-type01 mt-3"
          >
          </v-data-table>
        </div>
        <div class="centerBox ml-2"  @click="addScheduledLabel">
          <button class="btn-add">
            <img src="@/assets/img/ico-move-right02.png" alt="" />
            <strong>{{ $t('ADD') }}</strong>
          </button>
        </div>
        <div class="rightBox" style="width:400px;padding-left: 10px">
          <h3 class="section-title" >{{ $t('OTA FIRMWARE LIST') }}</h3>
          <v-data-table
            v-model="selecetedScheduledLabels"
            :headers="scheduledLabelHeaders"
            :items="scheduledLabels"
            :hide-default-footer="true"
            fixed-header
            :items-per-page="-1"
            height="300"
            class="tbl-type01 mt-3"
          >
            <template v-slot:[`item.del`]="item">
              <v-btn @click="removeScheduledLabel(item.item)" icon>
                <img src="@/assets/img/ico-delete3.png" alt="삭제" />
              </v-btn>
            </template>
          </v-data-table>
        </div>
      </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-center mt-15">
        <v-btn
          @click="handleOtaSettingSaveClick"
          :disabled="otaSettingEditDisabled"
          class="btn"
          text
          icon
        >
          {{ $t('Save') }}
        </v-btn>
        <v-btn text icon @click="cancelOtaSetting" class="btn">{{
          $t('Close')
        }}</v-btn>
      </v-card-actions>
    </v-card>
    <msg-box :msgBox="msgBox" @closeMsgBox="closeMsgBox" />
  </v-dialog>
</template>

<script>
// Utils
import codes from '@/plugins/codes'
import commons from '@/plugins/commons'
// Mixins
import MsgBoxParent from '@/mixins/MsgBoxParent'
import SelectedStoreWatcher from '@/mixins/SelectedStoreWatcher'
import LabelOtaMixin from '../mixins/LabelOtaMixin'

export default {
  mixins: [MsgBoxParent, SelectedStoreWatcher, LabelOtaMixin],
  name: 'OtaSetting',
  props: {
    otaSettingEditDisabled: {
      required: true,
      type: Boolean,
      default: false
    },
    otaSettingPopupDisabled: {
      required: true,
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // OTA setting
      otaSettingVisible: false,
      calendarVisible: false,
      otaScheduleDate: new Date().toISOString().substr(0, 10),
      otaScheduleTimeHour: null,
      otaScheduleTimeMinute: null,
      storeLabelHeaders: [
        { text: this.$t('SYSTEM'), value: 'system', width: '25%' },
        { text: this.$t('LABEL TYPE'), value: 'type', width: '35%' },
        { text: this.$t('CODE'), value: 'typeCode', width: '25%' }
      ],
      storeLabels: [],
      selectedStoreLabels: [],
      scheduledLabelHeaders: [
        { text: this.$t('SYSTEM'), value: 'system', width: '25%' },
        { text: this.$t('LABEL TYPE'), value: 'type', width: '35%' },
        { text: this.$t('CODE'), value: 'typeCode', width: '25%' },
        { text: '', value: 'del', width: '10%', sortable: false }
      ],
      scheduledLabels: [],
      selecetedScheduledLabels: [],
      otaFirmwareList: [],
      languageCodes: {
        English: 'en',
        한국어: 'ko',
        Deutsch: 'de',
        Francais: 'fr',
        中文: 'zh',
        Espanol: 'es',
        Italiano: 'it',
        Portugal: 'por',
        日本語: 'jp'
      }
    }
  },
  computed: {
    otaScheduleTimeHourOptions () {
      return [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23
      ]
    },
    otaScheduleTimeMinuteOptions () {
      return [0, 10, 20, 30, 40, 50]
    }
  },
  watch: {},
  mounted () {},
  methods: {
    // etc
    afterSelectedStoreUpdate () {
      this.initiateStoreSetting()
    },
    setOtaTime (otaTime) {
      if (!otaTime || otaTime.length < 5) {
        console.debug(`OTA time is invalid. OTA time: ${otaTime}`)
        return
      }
      // this.otaScheduleDate = otaTime.slice(0, 3).join('-') // YYYY-MM-DD
      this.otaScheduleDate = otaTime.slice(0, 10)// YYYY-MM-DD
      var ota = otaTime.split('T').pop().split(':')
      this.otaScheduleTimeHour = parseInt(ota[0])// HH
      this.otaScheduleTimeMinute = parseInt(ota[1])
      // this.otaScheduleTimeHour = otaTime[3] // HH
      // this.otaScheduleTimeMinute = otaTime[4] - (otaTime[4] % 10)
    },
    // An adaptor for store label list.
    convertStoreLabelListFromRes (storeLabelListFromRes) {
      if (storeLabelListFromRes) {
        return storeLabelListFromRes.map(storeLabel => {
          storeLabel.type = this.getTagTypeInfo(storeLabel, false)
          return storeLabel
        })
      }
    },
    getTagTypeInfo (tagStatus) {
      if (!commons.isValidData(tagStatus).result) return ''
      let { type, inch, typeCode } = tagStatus
      inch = `${inch.replace(/p|P/g, '.')}"`
      typeCode = `(${typeCode})`
      return `${type} ${inch} ${typeCode}`
    },
    async initiateStoreSetting () {
      const storeLabelData = await this.getStoreLabels(this.selectedStore?.code)
      this.otaFirmwareList = storeLabelData.otaFirmwareList
      if (this.otaFirmwareList) {
        const firmwareIDs = this.otaFirmwareList.split(',').map(id => parseInt(id))

        // Filtering storeLabelList based on IDs present in otaFirmwareList

        // Added on 05-12-2023 data is not showing OTA FIRMWARE LIST
        const filteredStoreLabelList1 = storeLabelData.storeLabelList.filter(item => !firmwareIDs.includes(item.id))
        const filteredStoreLabelList2 = storeLabelData.storeLabelList.filter(item => firmwareIDs.includes(item.id))

        this.storeLabels = this.convertStoreLabelListFromRes(filteredStoreLabelList1)
        this.scheduledLabels = this.convertStoreLabelListFromRes(filteredStoreLabelList2)

        // this.storeLabels = this.convertStoreLabelListFromRes(
        //   storeLabelData.storeLabelList.filter(
        //     label => !this.otaFirmwareList.includes(label.id)
        //   )
        // )
        // this.scheduledLabels = this.convertStoreLabelListFromRes(
        //   storeLabelData.storeLabelList.filter(label =>
        //     this.otaFirmwareList.includes(label.id)
        //   )
        // )
      } else {
        this.storeLabels = this.convertStoreLabelListFromRes(
          storeLabelData.storeLabelList
        )
      }
      this.setOtaTime(storeLabelData.otaTime)
    },
    cancelOtaSetting () {
      this.otaSettingVisible = false
      this.resetOtaSettingData()
    },
    resetOtaSettingData () {
      this.storeLabels = []
      this.selectedStoreLabels = []
      this.scheduledLabels = []
      this.selecetedScheduledLabels = []
      this.otaScheduleDate = new Date().toISOString().substr(0, 10)
      this.otaScheduleTimeHour = null
      this.otaScheduleTimeMinute = null
    },
    addScheduledLabel () {
      this.storeLabels = this.storeLabels.filter(
        label => !this.selectedStoreLabels.includes(label)
      )
      this.scheduledLabels = Array.from(
        new Set([...this.scheduledLabels, ...this.selectedStoreLabels])
      )
      this.selectedStoreLabels = []
    },
    removeScheduledLabel (scheduledLabel) {
      this.scheduledLabels = this.scheduledLabels.filter(
        label => label.id !== scheduledLabel.id
      )
      this.storeLabels.push(scheduledLabel)
    },
    // Event handlers
    async handleOtaSettingSaveClick () {
      const stationCode = this.$store.state.dataStore.selectedStore?.code
      if (
        !commons.isAllValid([
          this.otaScheduleDate,
          this.otaScheduleTimeHour,
          this.otaScheduleTimeMinute
        ])
      ) {
        this.openMsgBox(this.$t('Please enter the schedule setting.'))
        return
      }
      if (!this.scheduledLabels || this.scheduledLabels.length < 1) {
        this.openMsgBox(this.$t('Please add the labels to schedule.'))
        return
      }
      if (!commons.isValidStr(stationCode)) {
        this.openMsgBox(this.$t('Please select the store.'))
        return
      }
      this.otaSettingVisible = false
      await this.saveOtaSetting(stationCode)
      this.resetOtaSettingData()
    },
    // API
    getStoreLabels (stationCode) {
      if (!commons.isValidStr(stationCode)) return
      const config = { params: {} }
      return this.$utils
        .callAxios(
          codes.requests.getStoreLabels.method,
          `${codes.requests.getStoreLabels.url}/${stationCode}`,
          config
        )
        .then(res => {
          return res.data
        })
        .catch(error => {
          console.debug(`Could not find labels by station. error: ${error}`)
          return null
        })
    },
    convertOtaTime (
      otaScheduleDate,
      otaScheduleTimeHour,
      otaScheduleTimeMinute
    ) {
      otaScheduleDate = otaScheduleDate.split('-')
      otaScheduleDate[1] = commons.toValidTime(otaScheduleDate[1]) // MM
      otaScheduleDate[2] = commons.toValidTime(otaScheduleDate[2]) // DD
      return `${otaScheduleDate.join('')}${commons.toValidTime(
        otaScheduleTimeHour
      )}${commons.toValidTime(otaScheduleTimeMinute)}`
    },
    saveOtaSetting (stationCode) {
      const config = { params: {} }
      const body = {
        otaTime: this.convertOtaTime(
          this.otaScheduleDate,
          this.otaScheduleTimeHour,
          this.otaScheduleTimeMinute
        ),
        firmwareList: this.scheduledLabels.map(label => label.id)
      }
      this.$utils
        .callAxiosWithBody(
          codes.requests.saveOtaSetting.method,
          `${codes.requests.saveOtaSetting.url}/${stationCode}`,
          body,
          config
        )
        .then(res => {
          if (res.status === 200) this.openMsgBox(this.$t('Success'))
          throw new Error(
            `The response status code is not 200. code: ${res.status}`
          )
        })
        .catch(error => {
          if (error.response?.data?.responseCode === 400) {
            this.openMsgBox(this.$t(error.response.data.responseMessage))
          }
          if (error.response?.data?.responseCode === 204) { this.openMsgBox(this.$t(error.response.data.responseMessage)) }
          console.debug(`Failed to save OTA schedule setting. error: ${error}`)
          return []
        })
    }
  }
}
</script>

<style>
.disabled {
  background: #c9cace !important;
  pointer-events: none;
}
.centerBox {
  cursor: pointer;
  transition: 0.2s all;
}
.centerBox:hover {
  opacity: 0.9;
}
.btn-add {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}
/* Scroll bar stylings */
::v-deep ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    overflow:hidden
  }

  /* Track */
  ::v-deep ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  /* Handle */
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    }
</style>
