var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { scrollable: "", persistent: "", width: "auto " },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "btn-red ml-8",
                      class: _vm.otaSettingEditDisabled ? "disabled" : null,
                      attrs: {
                        disabled: _vm.otaSettingPopupDisabled,
                        text: ""
                      },
                      on: { click: _vm.initiateStoreSetting }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(" " + _vm._s(_vm.$t("OTA Setting")) + " ")]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.otaSettingVisible,
        callback: function($$v) {
          _vm.otaSettingVisible = $$v
        },
        expression: "otaSettingVisible"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup add_store_popup" },
        [
          _c("v-card-title", [
            _c("h3", { staticClass: "page-title-bar" }, [
              _c("i", { staticClass: "ico ico-multi" }),
              _vm._v(_vm._s(_vm.$t("OTA Setting")) + " ")
            ])
          ]),
          _c("v-card-text", [
            _c("div", { staticClass: "d-flex mt-5" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("span", { staticClass: "label-txt" }, [
                    _vm._v(_vm._s(_vm.$t("Update Start")))
                  ]),
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-content-click": false,
                        transition: "scale-transition",
                        "offset-y": "",
                        "min-width": "290px"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "form-input",
                                      attrs: {
                                        placeholder: "Input the date",
                                        outlined: "",
                                        dense: "",
                                        "hide-details": "",
                                        solo: "",
                                        readonly: ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "append",
                                            fn: function() {
                                              return [
                                                _c("img", {
                                                  attrs: {
                                                    width: "16",
                                                    height: "16",
                                                    src: require("@/assets/img/ico-calendar.png")
                                                  }
                                                })
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.otaScheduleDate,
                                        callback: function($$v) {
                                          _vm.otaScheduleDate = $$v
                                        },
                                        expression: "otaScheduleDate"
                                      }
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.calendarVisible,
                        callback: function($$v) {
                          _vm.calendarVisible = $$v
                        },
                        expression: "calendarVisible"
                      }
                    },
                    [
                      _c("v-date-picker", {
                        attrs: {
                          locale:
                            _vm.languageCodes[
                              _vm.$store.getters[
                                "dataStore/GET_CURRENT_LANGUAGE"
                              ]
                            ],
                          "no-title": "",
                          scrollable: ""
                        },
                        on: {
                          input: function($event) {
                            _vm.calendarVisible = false
                          }
                        },
                        model: {
                          value: _vm.otaScheduleDate,
                          callback: function($$v) {
                            _vm.otaScheduleDate = $$v
                          },
                          expression: "otaScheduleDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-select", {
                    staticClass: "form-select ml-3",
                    staticStyle: { width: "90px", "padding-top": "0px" },
                    attrs: {
                      items: _vm.otaScheduleTimeHourOptions,
                      placeholder: "--",
                      outlined: "",
                      dense: "",
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.otaScheduleTimeHour,
                      callback: function($$v) {
                        _vm.otaScheduleTimeHour = $$v
                      },
                      expression: "otaScheduleTimeHour"
                    }
                  }),
                  _c("v-select", {
                    staticClass: "form-select ml-2",
                    staticStyle: { width: "90px", "padding-top": "0px" },
                    attrs: {
                      items: _vm.otaScheduleTimeMinuteOptions,
                      placeholder: "--",
                      outlined: "",
                      dense: "",
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.otaScheduleTimeMinute,
                      callback: function($$v) {
                        _vm.otaScheduleTimeMinute = $$v
                      },
                      expression: "otaScheduleTimeMinute"
                    }
                  }),
                  _c("br")
                ],
                1
              )
            ]),
            _c("div", { staticClass: "d-flex mt-5" }, [
              _c("span", { staticClass: "label-txt" }, [
                _vm._v(
                  " * " +
                    _vm._s(
                      _vm.$t(
                        "The Update process runs regularly for up to 5 hours a day until it is completed."
                      )
                    )
                )
              ])
            ]),
            _c(
              "div",
              {
                staticClass: "flex-layout",
                staticStyle: { "padding-top": "20px" }
              },
              [
                _c(
                  "div",
                  { staticClass: "leftBox", staticStyle: { width: "350px" } },
                  [
                    _c("h3", { staticClass: "section-title" }, [
                      _vm._v(_vm._s(_vm.$t("STORE LABEL LIST")))
                    ]),
                    _c("v-data-table", {
                      staticClass: "tbl-type01 mt-3",
                      attrs: {
                        headers: _vm.storeLabelHeaders,
                        items: _vm.storeLabels,
                        "item-key": "typeCode",
                        "hide-default-footer": true,
                        "show-select": "",
                        "fixed-header": "",
                        "items-per-page": -1,
                        height: "300"
                      },
                      model: {
                        value: _vm.selectedStoreLabels,
                        callback: function($$v) {
                          _vm.selectedStoreLabels = $$v
                        },
                        expression: "selectedStoreLabels"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "centerBox ml-2",
                    on: { click: _vm.addScheduledLabel }
                  },
                  [
                    _c("button", { staticClass: "btn-add" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/ico-move-right02.png"),
                          alt: ""
                        }
                      }),
                      _c("strong", [_vm._v(_vm._s(_vm.$t("ADD")))])
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "rightBox",
                    staticStyle: { width: "400px", "padding-left": "10px" }
                  },
                  [
                    _c("h3", { staticClass: "section-title" }, [
                      _vm._v(_vm._s(_vm.$t("OTA FIRMWARE LIST")))
                    ]),
                    _c("v-data-table", {
                      staticClass: "tbl-type01 mt-3",
                      attrs: {
                        headers: _vm.scheduledLabelHeaders,
                        items: _vm.scheduledLabels,
                        "hide-default-footer": true,
                        "fixed-header": "",
                        "items-per-page": -1,
                        height: "300"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "item.del",
                            fn: function(item) {
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.removeScheduledLabel(
                                          item.item
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/img/ico-delete3.png"),
                                        alt: "삭제"
                                      }
                                    })
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.selecetedScheduledLabels,
                        callback: function($$v) {
                          _vm.selecetedScheduledLabels = $$v
                        },
                        expression: "selecetedScheduledLabels"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center mt-15" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: {
                    disabled: _vm.otaSettingEditDisabled,
                    text: "",
                    icon: ""
                  },
                  on: { click: _vm.handleOtaSettingSaveClick }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.cancelOtaSetting }
                },
                [_vm._v(_vm._s(_vm.$t("Close")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("msg-box", {
        attrs: { msgBox: _vm.msgBox },
        on: { closeMsgBox: _vm.closeMsgBox }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }