var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("h2", { staticClass: "page-title-bar" }, [
        _c("i", { staticClass: "ico ico-multi" }),
        _vm._v(_vm._s(_vm.$t("Label OTA")) + " ")
      ]),
      _c(
        "div",
        { staticClass: "d-flex mt-8" },
        [
          _c(
            "div",
            { staticClass: "form-group", staticStyle: { width: "190px" } },
            [
              _c("span", { staticClass: "label-txt" }, [
                _vm._v(_vm._s(_vm.$t("OTA On/Off")))
              ]),
              _c("v-select", {
                staticClass: "form-select",
                attrs: {
                  disabled: _vm.otaSettingEditDisabled,
                  items: _vm.otaScheduleEnabledItems,
                  "item-text": "txt",
                  "item-value": "val",
                  placeholder: _vm.$t("On"),
                  outlined: "",
                  dense: "",
                  "hide-details": ""
                },
                on: { input: _vm.handleOtaScheduleEnabledInput },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "span",
                          { staticStyle: { "font-size": "0.8125rem" } },
                          [_vm._v(_vm._s(_vm.$t(item.txt)))]
                        )
                      ]
                    }
                  },
                  {
                    key: "selection",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "LedPatternClass",
                            staticStyle: { display: "contents !important" }
                          },
                          [_vm._v(_vm._s(_vm.$t(item.txt)))]
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.otaScheduleEnabled,
                  callback: function($$v) {
                    _vm.otaScheduleEnabled = $$v
                  },
                  expression: "otaScheduleEnabled"
                }
              })
            ],
            1
          ),
          _c("ota-setting", {
            key: _vm.selectedStore.code,
            attrs: {
              otaSettingEditDisabled:
                _vm.otaSettingEditDisabled || !_vm.otaScheduleEnabled,
              otaSettingPopupDisabled: _vm.otaSettingPopupDisabled
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "updateStatus mt-13",
          class: !_vm.otaScheduleEnabled ? "is-off" : null
        },
        [
          _c("div", { staticClass: "total_update" }, [
            _c("div", [
              _c("p", { staticClass: "tit" }, [
                _c("strong", [_vm._v(_vm._s(_vm.$t("TOTAL LABEL")))]),
                _vm._v(_vm._s(_vm.$t("Update Status")) + " ")
              ]),
              _c("div", { staticClass: "graphBox" }, [
                _c("canvas", {
                  attrs: { id: "total", width: "220", height: "220" }
                }),
                _c("div", { staticClass: "score" }, [
                  _c("p", { staticClass: "percent" }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.totalTagStatusPercentage))
                    ]),
                    _vm._v("% ")
                  ]),
                  _c("p", { staticClass: "count" }, [
                    _vm._v(_vm._s(_vm.totalTagStatusFraction))
                  ])
                ])
              ])
            ])
          ]),
          !_vm.otaScheduleEnabled
            ? _c("div", { staticClass: "list_update custom-scrollbar" }, [
                _c(
                  "ul",
                  _vm._l(
                    [
                      { id: 0 },
                      { id: 1 },
                      { id: 2 },
                      { id: 3 },
                      { id: 4 },
                      { id: 5 },
                      { id: 6 }
                    ],
                    function(obj) {
                      return _c("li", { key: obj.id }, [
                        _c("p", { staticClass: "nodata" }, [
                          _vm._v(_vm._s(_vm.$t("No data available")))
                        ])
                      ])
                    }
                  ),
                  0
                )
              ])
            : _vm.tagStatusListExist
            ? _c("div", { staticClass: "list_update custom-scrollbar" }, [
                _c(
                  "ul",
                  _vm._l(_vm.tagStatusList, function(tagStatus, index) {
                    return _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "labelOtaEnabled",
                            rawName: "v-labelOtaEnabled",
                            value: tagStatus.otaMode,
                            expression: "tagStatus.otaMode"
                          }
                        ],
                        key: tagStatus.typeCode,
                        staticClass: "tagStatusList",
                        staticStyle: {
                          "justify-content": "unset",
                          "align-items": "stretch",
                          "flex-direction": "column"
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.getOtaStatus(tagStatus, index)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                              "justify-content": "space-between",
                              cursor: "pointer"
                            }
                          },
                          [
                            _c("p", { staticClass: "name" }, [
                              _vm._v(" " + _vm._s(tagStatus.system) + " "),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(_vm.getTagTypeInfo(tagStatus)) + " "
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "info_graph" }, [
                              _c("div", { staticClass: "graphBox" }, [
                                _c("div", { staticClass: "score" }, [
                                  _c("p", { staticClass: "percent" }, [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getTagUpdatedPercentage(tagStatus)
                                        )
                                      )
                                    ]),
                                    _vm._v("% ")
                                  ])
                                ])
                              ]),
                              _c("div", { staticClass: "data" }, [
                                _c("div", [
                                  _c("p", [
                                    _vm._v(" Quantity : "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getTagUpdatedFraction(tagStatus)
                                        )
                                      )
                                    ])
                                  ])
                                ])
                              ])
                            ])
                          ]
                        ),
                        _vm.toggleclass
                          ? _c("div", [
                              _vm.tagClassStatusList.length !== 0 &&
                              _vm.OtaStatusIndex === index
                                ? _c(
                                    "ul",
                                    _vm._l(_vm.tagClassStatusList, function(
                                      tagStatusDetail
                                    ) {
                                      return _c(
                                        "li",
                                        {
                                          directives: [
                                            {
                                              name: "labelOtaEnabled",
                                              rawName: "v-labelOtaEnabled",
                                              value: _vm.toggleclass,
                                              expression: "toggleclass"
                                            }
                                          ],
                                          key: tagStatusDetail.typeCode,
                                          staticClass: "tagStatusListDetail",
                                          class:
                                            tagStatusDetail.firmwareVersion ===
                                            0
                                              ? "detailClassFWtrue"
                                              : "detailClassFWfalse"
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass: "name",
                                              staticStyle: {
                                                "font-size": "14px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    tagStatusDetail.tagClass
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "info_graph" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "graphBox" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "score" },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "percent",
                                                          class:
                                                            tagStatusDetail.firmwareVersion ===
                                                            0
                                                              ? "detailpercentClassFWtrue"
                                                              : ""
                                                        },
                                                        [
                                                          _c("strong", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getTagUpdatedPercentageStatus(
                                                                  tagStatusDetail
                                                                )
                                                              )
                                                            )
                                                          ]),
                                                          _vm._v("% ")
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "data" },
                                                [
                                                  _c("div", [
                                                    _c("p", [
                                                      _vm._v(" Quantity : "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getTagUpdatedFractionStatus(
                                                              tagStatusDetail
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]),
                                                    _c("p", [
                                                      _vm._v(
                                                        " Firmware Version : "
                                                      ),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            tagStatusDetail.firmwareVersion
                                                              ? "V" +
                                                                  tagStatusDetail.firmwareVersion
                                                              : "V0"
                                                          )
                                                        )
                                                      ])
                                                    ])
                                                  ])
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e()
                            ])
                          : _vm._e()
                      ]
                    )
                  }),
                  0
                )
              ])
            : _c("div", { staticClass: "txt-no-data" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("No data available")))])
              ])
        ]
      ),
      _c("msg-box", {
        attrs: { msgBox: _vm.msgBox },
        on: { closeMsgBox: _vm.closeMsgBox }
      }),
      _c("br"),
      _c(
        "div",
        { staticStyle: { position: "relative", "margin-right": "50px" } },
        [
          _c("div", { staticClass: "pageInfo" }, [
            _vm._v(" " + _vm._s(_vm.pageInfoText) + " ")
          ]),
          _c(
            "div",
            [
              _c("v-pagination", {
                staticClass: "pageAlign",
                attrs: { length: _vm.totalPages, "total-visible": 7 },
                on: { input: _vm.paging },
                model: {
                  value: _vm.page,
                  callback: function($$v) {
                    _vm.page = $$v
                  },
                  expression: "page"
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "500" },
          model: {
            value: _vm.confirmBoxShow,
            callback: function($$v) {
              _vm.confirmBoxShow = $$v
            },
            expression: "confirmBoxShow"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "popup add_store_popup",
              attrs: { align: "center", justify: "center" }
            },
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("Are you sure you want to turn the OTA off?")
                        )
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: { click: _vm.afterConfirm }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Yes")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: { click: _vm.closeConfirm }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("No")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }