import commons from '@/plugins/commons'

// To use this mixin, you have to have afterSelectedStoreUpdate as a method
// in which you included this mixin.
const LabelOtaMixin = {
  components: {},
  props: {},
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    getTagTypeInfo (tagStatus, includeCode = true) {
      if (!commons.isValidData(tagStatus).result) return ''
      let { tagType, tagInch, tagTypeCode } = tagStatus
      tagInch = `${tagInch.replace(/p|P/g, '.')}"`
      tagTypeCode = `(${tagTypeCode})`
      return includeCode
        ? `${tagType} ${tagInch} ${tagTypeCode}`
        : `${tagType} ${tagInch}`
    }
  }
}

export default LabelOtaMixin
